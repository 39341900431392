export const Uint8ToBase64 = (u8Arr) => {
  let CHUNK_SIZE = 0x8000
  let index = 0
  let length = u8Arr.length
  let result = ''
  let slice
  while (index < length) {
      slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length))
      result += String.fromCharCode.apply(null, slice)
      index += CHUNK_SIZE
  }
  return btoa(result)
};
export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {
    type: mime
  });
};

export const base64toUint8 = (base64) => {
  const arr = base64.split(',');
  const bstr = atob(arr[0]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return u8arr;
};