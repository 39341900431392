<template>
  <section>
    <div class="modal-zoom" :class="{ 'modal-zoom-out': zoomOut &amp;&amp; isMobile }">
      <div class="container-fluid">
        <div class="d-flex">
          <router-link to="/" tag="h1" :class="[isAndroid ? '' : 'font-weight-bolder']" cursor-pointer>{{folders[folder]}}</router-link>
          <div class="mr-auto"></div>
          <Menu ref="menu" :folder="folder" :filter="filter" @settings="openServices" @mark-all-as-read="markAllUnread" @mark-all-as-unread="markAllRead"></Menu>
        </div>
        <div class="small text-black-50" v-if="filters[filter]" style="position:relative;bottom:0.25rem">{{filters[filter].toUpperCase()}}</div>
        <p class="fade" :class="getCode &amp;&amp; 'show'">
          {{ getName | caps }} 
          {{ getCode | code }}
        </p>
      </div>
      <div class="container-fluid" v-if="isAuthorized">
        <transition-group tag="div" v-if="documents" name="slide" @enter="startSliding" @after-enter="endSliding" @before-leave="startSliding" @after-leave="endSliding">
          <section v-for="doc, index in documents" :key="'doc' + doc.id">
            <Document
              :index="index"
              :id="doc.id"
              :doc="doc"
              :folder="folder"
              :is-read="doc.isRead"
              :is-open="currentDoc"
              @trash="trash"
              @toggle="toggle"
              @unread="unread"
              @move-to="moveTo"
              @public="handlePublic"
              @sign="openSignature"
            />
          </section>
        </transition-group>
        <div class="p-2 text-center" v-if="!isLast">
          <button class="btn btn-link text-secondary" @click="showMore($event, $el)" v-scroll="showMore"><i class="far fa-sync-alt" :class="loading &amp;&amp; 'fa-spin'"></i></button>
        </div>
      </div>
    </div>
    <ServicesForm :is-show="isShowServices" @close="closeServices"></ServicesForm>
    <Signature :is-show="isShowAuthentication" :id="currentDoc" @fetch="fetchIfEmpty" @close="closeAuthentication"/>
    <SuccessModal />
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import Menu from '@/components/Menu.vue'
import Signature from '@/components/Signature.vue'
import ServicesForm from '@/components/ServicesForm.vue'
import Document from '@/components/Document.vue'
import SuccessModal from '@/components/SuccessModal.vue'
import transitions from '../helpers/transitions.js'


export default {
  components: {
    Menu,
    Signature,
    ServicesForm,
    Document,
    SuccessModal,
  },
  data() {
    return {
      isAuthorizedTest: false,
      isShowServices: false,
      loading: false,
      limit: 10,
      folder: "/",
      filters: {
        unsigned: 'На підпис',
        signed: 'Підписані',
        inbound: 'Вхідні',
        outbound: 'Вихідні'
      },
      filter: "",
      folders: {
        "/": "Документи",
        favorites: "Улюблені",
        archive: "Архів"
      },
    };
  },
  computed: {
    ...mapGetters('app', [
      'isAndroid', 'isMobile', 'getName', 'getCode', 'isAuthorized',
      'isShowAuthentication', 'zoomOut',
    ]),
    ...mapGetters('docs', ['documents', 'currentDoc', 'offset', 'isLast']),
  },
  methods: {
    ...mapMutations('app', ['setLoading', 'setZoomOut', 'setIsShowAuthentication']),
    ...mapMutations('docs', ['setCurrentDoc', 'setIsLast', 'setDocuments']),
    ...mapActions('services', ['getActivatedServices']),
    ...mapActions('docs', [
      'fetchDocuments', 'deleteDocument', 'addDocumentToGroup', 'readDocument',
      'setPublicDocument',
    ]),
    async trash(id) {
      const replay = confirm("Ви впевнені що хочете видалити документ?");
      if (!replay) return;
      await this.deleteDocument(id);
    },
    async moveTo(group, id, removeFromView = false) {
      await this.addDocumentToGroup({group, id, removeFromView});
    },
    async toggle(id) {
      this.setCurrentDoc(this.currentDoc !== id ? id : '');

      if (this.getDoc(id) && !this.getDoc(id).isRead)
        await this.readDocument({ id, isRead: true })
    },
    async handlePublic (id, publicSwitch) {
      await this.setPublicDocument({ id, publicSwitch })
      if (publicSwitch) {
        this.setDocuments([]);
        await this.fetchDocuments(this.$route.query)
      }
    },
    async unread (id) {
      this.setCurrentDoc('');
      await this.readDocument({ id, isRead: false })
    },
    async read(id) {
      await this.readDocument({ id, isRead: true })
    },
    getDoc(id) {
      const docs = this.documents.filter(function (obj) {
        return obj.id == id;
      });
      return docs[0];
    },
    closeServices() {
      this.isShowServices = false;
      this.setZoomOut(false);
    },
    async openServices() {
      await this.getActivatedServices()
      this.isShowServices = true;
      this.setZoomOut(true);
    },
    markAllUnread() {
      const self = this;
      this.documents.forEach(function (doc) {
        if (doc.isRead) self.unread(doc.id);
      });
    },
    markAllRead() {
      const self = this;
      this.setCurrentDoc('');
      this.documents.forEach(function (doc) {
        if (!doc.isRead) self.read(doc.id);
      });
    },
    async fetch() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        folder: "/",
        limit: this.limit,
        offset: 0,
        clear: true
      };

      if (this.loading) return;
      this.setLoading(true);
      this.loading = true;
      this.setIsLast(false);
      this.setCurrentDoc('');

      if (params.clear) {
        this.setDocuments([]);
        delete params.clear;
      }

      params.folder = params.folder ? params.folder : "/";
      params.limit = params.limit ? params.limit : this.limit;
      params.offset = params.offset ? params.offset : 0;
      this.folder = params.folder;
      this.filter = params.filter;
      await this.fetchDocuments(params);
      this.setLoading(false);
      this.loading = false;
    },
    showMore(evt, el) {
      function isVivible(el) {
        var top = el.offsetTop;
        var height = el.offsetHeight;

        while (el.offsetParent) {
          el = el.offsetParent;
          top += el.offsetTop;
        }

        return top - 50 < window.pageYOffset + window.innerHeight && top + height > window.pageYOffset - 50;
      }
      if (isVivible(el)) {
        this.fetch({
          folder: this.folder,
          filter: this.filter,
          offset: this.offset + this.limit
        });
      }
    },
    fetchIfEmpty() {
      if (!this.documents.length) this.fetch();
    },
    openSignature() {
      // Check if we have keydata
      this.setZoomOut(true);
      this.setIsShowAuthentication(true);
    },
    closeAuthentication() {
      this.setZoomOut(false);
      this.setIsShowAuthentication(false);
    },
  },
  watch: {
    isAuthorized: function (val) {
      let params = this.$route.query;
      params.clear = true;
      if (val && !this.documents.length) this.fetch(params);
      if (!val && !this.isShowAuthentication) this.openSignature();
    },
  },
  mixins: [transitions],
  mounted() {
    let params = this.$route.query;
    params.clear = true;
    if (this.isAuthorized) {
      this.fetch(params);
    } else {
      this.setIsShowAuthentication(false);
      setTimeout(() => this.setIsShowAuthentication(true), 100);
    }
  },
}
</script>