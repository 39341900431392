<template >
  <section>
    <transition @enter="startFading" @after-enter="endFading" @before-leave="endFading" @after-leave="startFading">
      <div class="modal fade" v-if="isShow">
        <div class="modal-dialog modal-lg">
          <div class="modal-content border-0 shadow">
            <div class="modal-header">
              <h5 class="modal-title">Налаштування сповіщень</h5>
              <button class="btn btn-link close" type="button" @click.prevent="$emit('close')"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <div 
                v-for="(item, index) in listActivatedServices"
                :key="index"
              >
                <div class="d-flex align-items-center mt-2">
                  <span class="font-weight-bold">
                    {{`${item.services.name} ` || ''}}
                  </span>
                  <span 
                    v-for="(key, i) in Object.keys(item.params)"
                    :key="i"
                    class="white"
                  >
                    {{`${i === 0 ? ' (' : ''}${key}: ${item.params[key]}${
                      Object.keys(item.params).length > 1 && Object.keys(item.params).length - 1 > i ? ', ' : ''
                      }${Object.keys(item.params).length - 1 === i ? ')' : ''}`}}
                  </span>
                  <i class="far fa-trash-alt ml-2" cursor-pointer @click="() => deleteActivatedServices(item.id)"/>
                </div>
              </div>
              <div v-if="!visibleSelect" class="text-primary mt-2" cursor-pointer @click="handleSettings">
                + Додати
              </div>
              <p v-if="successConfirm" class="mt-2">
                За вказаним каналом були надіслані інструкції по активації
              </p>
              
              <form @submit.prevent="submitForm">
                <div v-if="visibleSelect">
                  <div class="mt-2">
                    <select class="form-control" id="serviceId" required="" @change="changeSelect">
                      <option selected="selected" value="">Обрати сервіс</option>
                      <option
                        v-for="(key, index) in getServices"
                        :key="index"
                        :value="key.id"
                      >{{key.name}}</option>
                    </select>
                    <div class="p-2"></div>
                  </div>
                </div>
                <p v-if="message">
                  {{message}}
                </p>
                <div v-if="visibleForm">
                  <div 
                    v-for="(key, index) in Object.keys(paramsForm)"
                    :key="index"
                    class="form-group"
                  >
                    <input
                      class="form-control"
                      spellcheck="false"
                      type="text"
                      :id="key"
                      :name="key"
                      v-model="params[key]"
                      :placeholder="paramsForm[key]"
                      required=""
                    >
                  </div>
          
                  <div class="form-group">
                    <button class="btn btn-primary" type="submit">
                      Створити
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="modal-backdrop fade" v-show="isShow" ref="backdrop" style="background-color: #f8f9fa"></div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import transitions from '../helpers/transitions.js'

export default {
  props: {
    isShow: Boolean,
  },
  data () {
    return {
      serviceId: '',
      visibleForm: false,
      visibleSelect: false,
      params: {},
      paramsForm: {},
      successConfirm: false,
      message: '',
    }
  },
  computed: {
    ...mapGetters('services', ['getServices', 'listActivatedServices']),
  },
  methods: {
    ...mapActions('services', ['getAllServices', 'postServicesConfirm', 'postServicesDisable', 'getActivatedServices']),
    async handleSettings () {
      await this.getAllServices();

      this.successConfirm = false;
      this.visibleSelect = true;
    },
    changeSelect (event) {
      this.serviceId = event.target.value;
      const findItem = this.getServices.find(item => item.id === Number(event.target.value));
      if (findItem) {
        this.paramsForm = findItem.params;
        this.message = findItem.user_request_msg || ''
        this.visibleForm = true;
        return;
      }
      this.visibleForm = false;
    },

    async deleteActivatedServices (id) {
      await this.postServicesDisable({
        activatedServicesId: id,
      })
      await this.getActivatedServices();
    },
  
    async submitForm () {
      await this.postServicesConfirm({
        serviceId: this.serviceId,
        params: this.params,
      })
      this.successConfirm = true;
      this.visibleForm = false;
      this.visibleSelect = false;
      this.serviceId = '';
      this.message = '';
      this.params = {};
      this.paramsForm = {};
    }
  },
  watch: {
    isShow: function (val) {
      if (!val) this.successConfirm = false;
    },
  },
  mixins: [transitions],
}
</script>