
<template>
  <div class="row border-bottom" :class="!index &amp;&amp; 'border-top'" :id="id">
    <div class="col-12 py-3" :class="[id == isOpen ? 'bg-light' : 'bg-white']" :style="{ '--i': index }">
      <div class="d-flex"><a class="stretched-link nav-link text-dark text-truncate p-0" href="#" @click.prevent="$emit('toggle', id);">
          <div class="status-icon" :class="{ 'show': !isRead }"><i class="fas fa-fw text-primary my-1" :class="{'fa-circle': !isRead}"></i></div>
          <template v-if="getCode === doc.code">
            <span class="font-weight-bold" v-for="signature, index in doc.attachment.signatures" :key="signature.code">
              {{signature.organization | organization}} {{signature.name}}
              <span v-if="doc.attachment.signatures.length &gt; index + 1">, </span>
            </span>
          </template>
          <template v-else><span class="font-weight-bold">{{doc.from}}</span></template></a>
        <div class="ml-auto text-muted small my-1 text-nowrap pl-1">{{ doc.timestamp | calendar }}<i class="far fa-fw fa-chevron-right ml-1" :class="[id == isOpen ? 'toggle-down' : 'toggle-up']"></i></div>
      </div>
      <div class="d-flex">
        <div :class="{ 'text-truncate': id != isOpen }">
          <div class="status-icon" :class="{ 'show': doc.folder === 'favorites' }"><i class="fas fa-star mr-2 my-1 text-warning"></i></div><span>{{ doc.subject }}</span>
        </div>
        <div class="ml-auto"><i class="far ml-2" :class="{ 'fa-signature text-primary' : signatures.isAction, 'fa-check text-success' : signatures.isSigned}"></i></div>
      </div>
    </div>
    <transition name="slide" @enter="startSliding" @after-enter="endSliding" @before-leave="startSliding" @after-leave="endSliding">
      <div class="col-12 overflow-hidden" v-if="id == isOpen">
        <p>{{ doc.message }}</p>
        <div class="small text-muted">Створено {{ doc.timestamp | timestamp }}</div><span class="text-monospace">{{ doc.code }}</span>
        {{ doc.from | caps }}
        <section v-if="doc.attachment">
          <div class="mt-3">
            <div class="small text-muted">Оригінальний документ</div>
            <i class="far fa-fw" :class="getIcon"></i>
            <span class="text-primary" cursor-pointer @click="() => downloadAttachment(doc.attachment.originalPath, '')">
              {{ doc.attachment.name }}
            </span>
          </div>

          <div v-if="doc.attachment.path">
            <div class="mt-3">
              <div class="small text-muted">Підписаний документ</div>
              <i class="far fa-fw" :class="getIcon"></i>
              <span class="text-primary" cursor-pointer @click="() => downloadAttachment(doc.attachment.path, 'sign')">
                {{ doc.attachment.name }}
              </span>
            </div>
          </div>

          <div v-if="doc.public && doc.publicPath">
            <div class="mt-3">
              <div class="small text-muted">Публічний документ</div>
              <i class="far fa-fw" :class="getIcon"></i>
              <a download :href="doc.publicPath">
                {{ doc.attachment.name }}
              </a>
            </div>
          </div>

          <div v-if="doc.public && doc.publicUrl && generateUrl">
            <div class="mt-3">
              <i class="far fa-fw" :class="getIcon"></i>
              <a target="_blank" :href="generateUrl">
                Публічне посилання
              </a>
              <a title="Скопіювати в буфер" @click="() => copyUrl(generateUrl)">
                <i cursor-pointer class="far fa-copy ml-2"></i>
              </a>
            </div>
          </div>
          <div class="p-2"></div>
          <section v-if="signatures">
            <section v-if="signatures.sign &amp;&amp; signatures.sign.length">
              <div class="small text-muted">Підписано</div>
              <section v-for="signature in signatures.sign" :key="signature.code">
                <i class="far fa-fw mr-2" :class="[signature.date ? 'fa-signature' : 'fa-clock']"></i>
                <span class="text-monospace">{{ signature.code }}</span>
                {{ signature.name | caps }}
                <div class="small">{{ signature.date | timestamp }}</div>
                <div class="p-1"></div>
              </section>
            </section>
            <section v-if="signatures.wait &amp;&amp; signatures.wait.length">
              <div class="small text-muted">Очікуємо підписи</div>
              <section v-for="signature in signatures.wait" :key="signature.code">
                <i class="far fa-fw mr-2" :class="[signature.date ? 'fa-signature' : 'fa-clock']"></i>
                <span class="text-monospace">{{ signature.code }}</span>
                {{ signature.name | caps }}
                <div class="p-1"></div>
              </section>
            </section>
            <section v-if="signatures.self &amp;&amp; signatures.self.length">
              <div class="small text-muted">Ваш підпис</div>
              <section v-for="signature in signatures.self" :key="signature.code">
                <i class="far fa-fw mr-2" :class="[signature.date ? 'fa-signature' : 'fa-clock']"></i>
                <span class="text-monospace">{{ signature.code }}</span>
                {{ signature.name | caps }}
                <div class="p-1"></div>
                <button class="btn btn-primary" v-if="signature.code === signature.code" @click.prevent="$emit('sign')">
                  <i class="far fa-fw mr-2" :class="{ 'fa-circle-notch fa-spin': signing, 'fa-pen': !signing }"></i>
                  Підписати
                </button>
                <div class="p-1"></div>
              </section>
            </section>
          </section>
        </section>
        <hr>
        <div class="row">
          <div class="col">
            <div class="d-flex" v-if="folder === 'archive'">
              <button class="btn btn-danger" @click.prevent="$emit('trash', id)"><i class="far fa-trash mr-1"></i>Видалити</button>
              <div class="p-1"></div>
              <button class="btn btn-light" @click.prevent="$emit('move-to', '/', id, true)"><i class="far fa-undo mr-1"></i>Воскресити</button>
            </div>
            <div class="d-flex align-items-center flex-wrap" v-else>
              <button class="btn btn-light" @click.prevent="$emit('unread', id)"><i class="far fa-envelope mr-1"></i>Непрочитане</button>
              <div class="p-1"></div>
              <button class="btn btn-light" @click.prevent="$emit('move-to', 'archive', id, true)"><i class="far fa-archive mr-1"></i>До&nbsp;архіву</button>
              <div class="p-1"></div>
              <button class="btn btn-light" @click.prevent="$emit('move-to', doc.folder === 'favorites' ? '/':'favorites', id)">
                <i class="fa-star" :class="[doc.folder === 'favorites' ? 'fas':'far']"></i>
              </button>
              <div class="p-1"></div>
              <div class="custom-control custom-switch pt-md-2">
                <input class="custom-control-input" type="checkbox" id="public_switch" :checked="doc.public === true" @click="$emit('public', id, !doc.public)">
                <label class="custom-control-label" for="public_switch">Публічний</label>
              </div>
            </div>
            <div class="p-2"></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex'
import transitions from '../helpers/transitions.js'

export default {
  props: {
    doc: Object,
    id: String,
    code: String,
    folder: String,
    open: String,
    isOpen: String,
    isRead: Boolean,
    index: Number
  },
  data: function data() {
    return {
      signing: false,
      icons: {
        "doc": "fa-file-word",
        "pdf": "fa-file-pdf",
        "txt": "fa-file-alt",
        "rtf": "fa-file-alt"
      },
    };
  },
  mixins: [transitions],
  methods: {
    ...mapActions('docs', ['downloadFileAsync']),
    ...mapMutations('docs', ['setCurrentDoc', 'setOpenDocId']),
    isAction() {
      return this.doc.attachment && this.doc.attachment.action && this.doc.attachment.action.type === "sign";
    },
    copyUrl (url) {
      navigator.clipboard.writeText(url)
    },
    async downloadAttachment (path, type) {
      await this.downloadFileAsync({ path, type, name: this.doc.attachment.name })
    },
  },
  computed: {
    ...mapGetters('app', [
      'isAndroid', 'isMobile', 'getName', 'getCode'
    ]),
    ...mapGetters('docs', ['openDocId']),
    generateUrl() {
      return `${location.origin}/public/${this.id}`
    },
    getIcon () {
      return this.icons[this.doc.attachment.type] || this.icons[this.doc.attachment.name.split('.')[1]]
    },
    signatures() {
      const _this = this;

      const signatures = this.doc.attachment.signatures;
      return {
        self: signatures.filter(function (item) {
          return !item.date && item.code === _this.getCode;
        }),
        sign: signatures.filter(function (item) {
          return item.date;
        }),
        wait: signatures.filter(function (item) {
          return item.code != _this.getCode && !item.date;
        }),
        isSigned: signatures.filter(function (item) {
          return !item.date;
        }).length ? false : true,
        isAction: signatures.filter(function (item) {
          return !item.date && item.code === _this.getCode;
        }).length ? true : false
      };
    }
  },
  mounted() {
    if (this.openDocId && this.openDocId === this.id) {
      const findDoc = document.getElementById(this.id);
      window.scrollTo({ top: findDoc.offsetTop, behavior: 'smooth' });
      this.setCurrentDoc(this.id)
      this.setOpenDocId('')
    }
  },
}
</script>