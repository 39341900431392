<template>
  <div class="btn-group dropdown">
    <button class="btn btn-link" type="button" @click.stop="toggleMenu" ref="folder"><i class="far fa-ellipsis-v fa-fw fa-lg" :class="isShow"></i></button>
    <transition enter-active-class="animated fadeInDown superfaster" leave-active-class="animated fadeOutUp superfaster">
      <div class="dropdown-menu dropdown-menu-right show shadow border-0" v-show="isShow" v-click-outside="{ exclude: ['folder'], handler: 'closeDropdown'}">
        <router-link class="dropdown-item" :to="'/new-document'">
          <i class="far mr-2 fa-file-plus"></i>
          <span>Створити новий документ</span>
          <div class="m-1 badge badge-primary float-right"></div>
        </router-link>
        <div class="dropdown-divider"></div>
        <h6 class="dropdown-header">Папки</h6>
        <router-link class="dropdown-item" :to="'/docs'"><i class="far mr-2 fa-check my-1" v-show="folder === '/'"></i><span>Всі</span>
        </router-link>
        <router-link class="dropdown-item" :to="'/docs?folder=favorites'"><i class="far mr-2 fa-check my-1" v-show="folder === 'favorites'"></i><span>Улюблені</span>
        </router-link>
        <router-link class="dropdown-item" :to="'/docs?folder=archive'"><i class="far mr-2 fa-check my-1" v-show="folder === 'archive'"></i><span>Архів</span>
        </router-link>
        <h6 class="dropdown-header">Фільтри</h6>
        <router-link class="dropdown-item" :to="'/docs?' + folderURL + '&amp;filter=unsigned'"><i class="far mr-2 my-1" :class="[filter === 'unsigned' ? 'fa-dot-circle':'fa-circle']"></i><span>На підпис</span>
        </router-link>
        <router-link class="dropdown-item" :to="'/docs?' + folderURL + '&amp;filter=signed'"><i class="far mr-2 my-1" :class="[filter === 'signed' ? 'fa-dot-circle':'fa-circle']"></i><span>Підписані</span>
        </router-link>
        <router-link class="dropdown-item" :to="'/docs?' + folderURL + '&amp;filter=inbound'"><i class="far mr-2 my-1" :class="[filter === 'inbound' ? 'fa-dot-circle':'fa-circle']"></i><span>Вхідні</span>
        </router-link>
        <router-link class="dropdown-item" :to="'/docs?' + folderURL + '&amp;filter=outbound'"><i class="far mr-2 my-1" :class="[filter === 'outbound' ? 'fa-dot-circle':'fa-circle']"></i><span>Вихідні</span>
        </router-link>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#" @click.prevent="event('mark-all-as-read', 'read')">
          <i class="far mr-2 fa-envelope"></i><span>Помітити як непрочитані</span>
          <div class="m-1 badge badge-primary float-right"></div>
        </a>
        <a class="dropdown-item" href="#" @click.prevent="event('mark-all-as-unread', 'unread')">
          <i class="far mr-2 fa-envelope-open"></i><span>Помітити як прочитані</span>
        <div class="m-1 badge badge-primary float-right"></div></a>

        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#" @click.prevent="event('settings')">
          <i class="far mr-2 fa-cog"></i><span>Сповіщення</span>
          <div class="m-1 badge badge-primary float-right"></div>
        </a>

        <div class="dropdown-divider"></div>
        <a class="dropdown-item" role="button" @click="logoutClient">
          <i class="far mr-2 fa-sign-out"></i>
          <span>Вийти</span>
          <div class="m-1 badge badge-primary float-right"></div>
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    folder: String,
    filter: String
  },
  data() {
    return {
      isShow: false,
      currentItem: ""
    };
  },
  computed: {
    folderURL() {
      return this.folder != '/' ? 'folder=' + this.folder : '';
    },
  },
  methods: {
    ...mapActions('app', ['logout']),
    toggleMenu() {
      this.isShow = !this.isShow;
    },
    closeDropdown() {
      this.isShow = false;
    },
    event(name, value) {
      this.$emit(name, value);
      var self = this;
      setTimeout(function () {
        self.closeDropdown();
      }, 125);
    },
    show(name) {
      if (this.currentItem === name) {
        this.currentItem = "";
        this.event("show-folder");
      } else {
        this.currentItem = name;
        this.event("show-".concat(name));
      }
    },
    async logoutClient () {
      await this.logout()
      this.isShow = false;
    },
  },
}
</script>
