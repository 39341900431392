<template>
  <section>
    <transition @enter="startFading" @after-enter="endFading" @before-leave="endFading" @after-leave="startFading">
      <div class="modal fade" v-if="isShow">
        <div class="modal-dialog">
          <div class="modal-content border-0 shadow">
            <div class="modal-header">
                <h5 class="modal-title">Аутентифікація</h5>
                <button class="btn btn-link close" v-show="isAuthorized" type="button" @click.prevent="$emit('close')"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <transition name="slide" @enter="startSliding" @after-enter="endSliding" @before-leave="startSliding" @after-leave="endSliding">
              <section v-if="error">
                  <div class="alert alert-warning">{{error}}</div>
              </section>
              </transition>
              <form action="#" method="post" @submit.prevent="submit">
              <fieldset :disabled="loading || hasKey()">
                <div class="form-group">
                <label for="customFile">Електронний підпис</label>
                <div class="custom-file">
                  <input class="custom-file-input" id="customFile" type="file" @change="openFile" ref="file" required="">
                  <label class="custom-file-label" for="customFile" v-text="signature.file.name">Оберить ЕЦП</label>
                </div>
                </div>
                <transition name="slide" @enter="startSliding" @after-enter="endSliding" @before-leave="startSliding" @after-leave="endSliding">
                <section v-if="signature.keys.length" key="keys">
                  <div class="form-group m-0">
                  <select class="form-control" required="" v-model="signature.key">
                    <option selected="selected" value="">Обрати підпис</option>
                    <option
                      v-for="(key, index) in signature.keys"
                      :key="index"
                      :value="key.alias"
                    >{{key.name}}
                    </option>
                  </select>
                  <div class="p-2"></div>
                  </div>
                </section>
                </transition>
                <div class="form-group">
                <template v-if="signature.keys.length">
                  <input class="form-control d-none" v-model="signature.key" type="text" autocomplete="username" spellcheck="false" aria-label="Username" name="Username">
                </template>
                <template v-else>
                  <input class="form-control d-none" v-model="signature.file.name" type="text" autocomplete="username" spellcheck="false" aria-label="Username" name="Username">
                </template>
                <input class="form-control" v-model="signature.password" required="" placeholder="Пароль" type="password" autocomplete="current-password" spellcheck="false" tabindex="0" aria-label="Confirm" name="ConfirmPasswd">
                </div>
                <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" id="rememberme" type="checkbox" v-model="signature.rememberMe">
                  <label class="custom-control-label" for="rememberme">Запам'ятати</label>
                </div>
                </div>
              </fieldset>
              <div class="form-group">
                <button class="btn btn-primary" type="submit" :disabled="loading">
                  <span v-if="id"><i class="far fa-fw mr-1" :class="[loading ? 'fa-spin fa-circle-notch' : 'fa-signature']"></i>Поставити підпис</span>
                  <span v-else><i class="far fa-fw mr-1" :class="[loading ? 'fa-spin fa-circle-notch' : 'fa-sign-in']"></i>Увійти</span>
                </button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="modal-backdrop fade" v-show="isShow" ref="backdrop" style="background-color: #f8f9fa"></div>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import transitions from '../helpers/transitions.js'
import { Uint8ToBase64, base64toUint8 } from '../helpers/formatter.js'
import { EndUser, EndUserConstants } from 'euscp';

export default {
  props: {
    isShow: Boolean,
    id: String
  },
  data() {
    return {
      loading: false,
      attachment: {},
      filename: '',
      signature: {
        key: "",
        keys: [],
        file: {
            name: "Оберить ЕЦП"
        },
        password: "",
        rememberMe: false,
      },
      error: '',
      password: '',

      euSign: new EndUser(null, EndUserConstants.EndUserLibraryType.JS),
      // Бібліотека для роботи з файловими ключами, що не потребує встановлення додатково ПЗ
      euSignFile: new EndUser(null, EndUserConstants.EndUserLibraryType.JS),
      // Бібліотека для роботи з аппаратними носіями, що потребує встановлення додатково ПЗ бібліотек web-підпису, web-розширення для браузера
      euSignKeyMedia: new EndUser(null, EndUserConstants.EndUserLibraryType.SW),
    };
  },
  computed: {
    ...mapGetters('app', ['isAuthorized']),
    ...mapGetters('services', ['getServiceConfirmKey']),
  },
  methods: {
    ...mapMutations('app', ['setLoading']),
    ...mapMutations('services', ['setIsOpenSuccessModal']),
    ...mapActions('app', ['login']),
    ...mapActions('docs', ['checkDocumentForSubscription', 'signDocumentAndSave']),
    ...mapActions('services', ['postCheckConfirm']),
    getFileExtension (filename) {
      return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
    },
    hasKey() {
      // Check if localStorage is empty
      return localStorage.getItem('file');
    },
    clearSignature () {
      this.signature = {
        key: '',
        keys: [],
        password: '',
        file: {
          name: 'Оберіть ЕЦП',
        },
        rememberMe: false,
      }
    },
    checkFile () {
      if (localStorage.getItem('file')) {
        this.signature.file.data = base64toUint8(localStorage.getItem('file'))
        this.signature.file.name = localStorage.getItem('filename')
        this.signature.password = localStorage.getItem('password')
        this.signature.rememberMe = true
        if (localStorage.getItem('privateKey')) {
        this.signature.keys = [
          {
            privateKey: base64toUint8(localStorage.getItem('privateKey')),
            name: localStorage.getItem('key'),
            alias: localStorage.getItem('key'),
          }
        ]
        this.signature.key = localStorage.getItem('key')
        }
      }
    },
    async initialize (euSignInitial) {
      // Налаштування бібліотеки
      const euSettings = {
        language: 'uk',
        encoding: 'utf-8',
        httpProxyServiceURL: 'https://staging.lexberry.pro/test/ProxyHandler.php',
        directAccess: true,
        CAs: '/data/CAs.json',
        CACertificates: '/data/CACertificates.p7b',
        allowedKeyMediaTypes: [
          'е.ключ ІІТ Алмаз-1К',
          'е.ключ ІІТ Кристал-1',
          'ID-карта громадянина (БЕН)',
          'е.ключ ІІТ Алмаз-1К (PKCS#11)',
          'е.ключ ІІТ Кристал-1 (PKCS#11)',
        ],
      };
    //   if (euSignInitial == this.euSignFile) {
        const result = await euSignInitial.IsInitialized();
        if (result) {
          return;
        }
        return await euSignInitial.Initialize(euSettings);
    //   } else {
    //     // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
    //     const result = await euSignInitial.GetLibraryInfo();
    //     if (!result.supported) {
    //       this.alertText = 'Бібліотека web-підпису не підтримується в вашому браузері або ОС';
    //       return;
    //     }
    //     if (!result.loaded) {
    //       // Бібліотека встановлена, але потребує оновлення
    //       if (result.isNativeLibraryNeedUpdate) {
    //         this.alertText = 'Бібліотека web-підпису потребує оновлення. Будь ласка, встановіть оновлення та оновіть сторінку';
    //         this.alertUrl = result.nativeLibraryInstallURL;
    //         return;
    //       }
          
    //       // Якщо браузер підтримує web-розширення рекомендується встановлювати web-розширення
    //       // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
    //       if (result.isWebExtensionSupported && !result.isWebExtensionInstalled) {
    //         this.alertText = 'Бібліотека web-підпису потребує встановлення web-розширення. Будь ласка, встановіть web-розширення та оновіть сторінку';
    //         this.alertUrl = result.webExtensionInstallURL;
    //         return;
    //       }
          
    //       // Бібліотека (нативні модулі) не встановлені
    //       this.alertText = 'Бібліотека web-підпису потребує встановлення. Будь ласка, встановіть бібліотеку та оновіть сторінку';
    //       this.alertUrl = result.nativeLibraryInstallURL;
    //       return;
    //     } 
    //     const resultMedia = await euSignInitial.IsInitialized();
    //     if (resultMedia) {
    //       return;
    //     }
    //     return await euSignInitial.Initialize(euSettings);
    //   }
    },
    // async handleTypeKey (e) {
    //   this.typeKey = e.target.value
      
    //   this.loaderMedia = true;
    //   this.alertText = '';
    //   this.alertUrl = '';
    //   this.clearSignature();
    //   try {
    //     if (e.target.value === 'hardware') {
    //       // this.mediaName = '';
    //       this.euSign = this.euSignKeyMedia;
    //       await this.initialize(this.euSignKeyMedia);
    //       const keysMedia = await this.euSignKeyMedia.GetKeyMedias();
    //       const arrayMedia = keysMedia.map((item) => (
    //         {
    //           ...item,
    //           value: item.device,
    //           name: item.visibleName,
    //         }
    //       ));
    //       this.keyMedias = arrayMedia;
    //     } else {
    //       // dispatch(change(formName, 'key', ''));
    //       // dispatch(change(formName, 'file', ''));
    //       this.euSign = this.euSignFile;
    //       await this.initialize(this.euSignFile);
    //       console.log('object')
    //     }
    //   } catch (error) {
    //     if (error?.code || error?.errorCode) {
    //       this.alert(error.code || error.errorCode)
    //     }
    //   }
    //   this.loaderMedia = false;
    // },
    async readFile (file) {
      return new Promise(function(resolve) {
        const reader = new FileReader();
        reader.onloadend  = function(evt) {
          if (evt.target.readyState != FileReader.DONE)
            return;
          resolve({
            ...file,
            name: file.name,
            data: new Uint8Array(evt.target.result),
          });
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async openFile () {
      this.loading = true;
      this.error = '';
      this.signature.keys = [];
      this.signature.key = ''

      if (!this.$refs.file.files.length) {
        this.loading = false;
        this.signature.file = {
          name: "Оберить ЕЦП"
        };
        return;
      }

      var file = this.$refs.file.files[0];
      const newFile = await this.readFile(file);      

      this.signature.file = newFile

      if (this.getFileExtension(newFile.name) === 'jks') {
        const keys = await this.euSign.GetJKSPrivateKeys(newFile.data)
        keys.forEach(key => {
          this.signature.keys.push({
            ...key,
            alias: key.alias,
            name: key.alias,
          })
        })
        if (this.signature.keys.length === 1) this.signature.key = this.signature.keys[0].alias
      }
      this.loading = false;
    },
    async submitKeyAndPassword () {
      // if (this.euSign == this.euSignFile) {
        if (this.signature.file.name.endsWith(".jks")) {
          const jksKey = this.signature.keys.find(key => key.alias === this.signature.key || '' );
          // console.log(jksKey)
          // console.log(this.signature.password)
          return await this.euSign.ReadPrivateKeyBinary(jksKey.privateKey, this.signature.password, null, null);
        } else {
          return await this.euSign.ReadPrivateKeyBinary(this.signature.file.data, this.signature.password, null, null);
        }
      // } else {
      //     const selectedKM = this.keyMedias.find((item) => item.device === this.mediaName);
      //     const keyMedia = new EndUserKeyMedia(selectedKM || {});
      //     keyMedia.password = this.signature.password;
      //     return await this.euSign.ReadPrivateKey(keyMedia, null, null);
      // }
    },
    saveSignatureToLocalStorage () {
      if (this.signature.rememberMe) {
        localStorage.setItem('file', Uint8ToBase64(this.signature.file.data));
        localStorage.setItem('filename', this.signature.file.name);
        localStorage.setItem('password', this.signature.password);
        if (this.signature.key) {
          const jksKey = this.signature.keys.find(key => key.alias === this.signature.key );
          localStorage.setItem('privateKey', Uint8ToBase64(jksKey.privateKey));
          localStorage.setItem('key', this.signature.key);
        }
      } else {
        localStorage.removeItem('file');
        localStorage.removeItem('filename');
        localStorage.removeItem('privateKey');
        localStorage.removeItem('key');
        localStorage.removeItem('password');
      }
    },
    async submit () {
      const self = this;
      try {
        this.loading = true;
        this.setLoading(true);
        this.euSign = this.euSignFile;
        await this.initialize(this.euSign);
        const keyData = await this.submitKeyAndPassword();
        const { serial, issuerCN, subjOrg } = keyData;
        const ownCertificates = await this.euSign.GetOwnCertificates();
        if (this.isAuthorized) {
          const data = await self.checkDocumentForSubscription({ id: this.id })
          if (!data.file)
            return this.error = 'На жаль документ не доступний для підпису. Спробуйте знову через 3 хвилини';
          
          const signData = await this.euSign.SignDataInternal(true, data.file, true);
          await this.signDocumentAndSave({
            organization: subjOrg,
            authority: issuerCN,
            serial: serial,
            file: 'data:application/pkcs7-signature;base64,' + signData,
            id: this.id
          })
          if (!this.hasKey()) this.saveSignatureToLocalStorage();
          self.$emit('close');
        } else {
          const response = await this.login({
            certificates: Uint8ToBase64(ownCertificates[0].data),
          })
          if (response) {
            this.saveSignatureToLocalStorage();
            this.$emit('fetch');
            this.$emit('close');
            if (this.getServiceConfirmKey) {
              await this.postCheckConfirm({ serviceConfirmKey: this.getServiceConfirmKey });
              this.setIsOpenSuccessModal(true);
            }
          } else {
            this.error = 'Помилка авторизації, спробуйте оновити данні входу та спробувати ще раз';
          }
        }
        this.setLoading(false);
        this.loading = false;
      } catch (error) {
        this.setLoading(false);
        this.loading = false;
        this.error = error.message
      }
    },
  },
  watch: {
    isAuthorized: function (val) {
      if (!val) this.clearSignature();
    },
  },
  created () {
    this.clearSignature()
  },
  beforeMount () {
    this.checkFile()
  },
  mixins: [transitions],
}
</script>
