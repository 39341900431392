<template >
  <section>
    <transition @enter="startFading" @after-enter="endFading" @before-leave="endFading" @after-leave="startFading">
      <div class="modal fade" v-if="isOpenSuccessModal">
        <div class="modal-dialog">
          <div class="modal-content border-0 shadow">
            <div class="modal-header">
              <button class="btn btn-link close" type="button" @click.prevent="closeModal"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <p>{{errorMessageServiceConfirm || 'Сервіс сповіщень успішно активований'}}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">Зрозуміло</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="modal-backdrop fade" v-show="isOpenSuccessModal" ref="backdrop" style="background-color: #f8f9fa"></div>
  </section>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import transitions from '../helpers/transitions.js'

export default {
  computed: {
    ...mapGetters('services', ['errorMessageServiceConfirm', 'isOpenSuccessModal']),
  },
  methods: {
    ...mapMutations('services', ['setErrorMessageServiceConfirm', 'setIsOpenSuccessModal']),

    closeModal () {
      this.$router.replace({ query: {} });
      this.setErrorMessageServiceConfirm('');
      this.setIsOpenSuccessModal(false);
    }
  },
  mixins: [transitions],
}
</script>